var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav',{staticStyle:{"justify-content":"space-between"}},[_c('button',{staticClass:"button button-fill",class:{disabled: _vm.activeTab==0},on:{"click":function($event){return _vm.$refs['main-tabs'].prevTab()}}},[_c('fa-icon',{attrs:{"icon":['fas', 'chevron-left']}}),_c('span',[_vm._v(_vm._s(_vm.$t('back')))])],1),(_vm.activeTab<1)?_c('button',{staticClass:"button button-fill",on:{"click":function($event){return _vm.$refs['main-tabs'].nextTab()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('next')))]),_c('fa-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1):_vm._e(),(_vm.activeTab==1&&_vm.clientesSelected().length)?_c('button',{staticClass:"button button-fill",on:{"click":_vm.finalizar}},[_c('span',[_vm._v("Guardar")])]):_vm._e()]),_c('section',[_c('article',{staticStyle:{"height":"395px"}},[_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"0px","left":"0px","height":"395px"},attrs:{"buttons":[
          '1. Selección/Acotación',
          '2. Confirmación'
        ],"styleButton":'width:50%;'},on:{"active":_vm.onActiveTab}},[_c('div',{staticClass:"tab"},[_c('filtro-seleccion-acotacion',{staticStyle:{"left":"5px","top":"80px"},attrs:{"referencia":"actualizar_ivare","model":"cliente","nombre":"Actualizar IVA/RE"}})],1),_c('div',{staticClass:"tab"},[_c('field',{ref:"clientes",staticStyle:{"top":"10px","left":"4px","width":"648px"},attrs:{"name":"clientes","widget":"handsontable","height":345,"width":950,"minRows":15,"columns":[
              {name:'sel',header:'Sel.', widget: 'checkbox', help: 'Seleccionado'},
              {name:'iva',header:'IVA',type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'re',header:'RE',type:'numeric',numericFormat: { pattern: '0,0.00'}},
              {name:'nombre_fiscal',header:'Nombre fiscal',readOnly:true},
              {name:'nombre_comercial',header:'Nombre comercial',readOnly:true},
              {name:'nif_cif',header:'NIF/CIF',readOnly:true},
              {name:'cp_fiscal',header:'CP',readOnly:true},
              {name:'poblacion_fiscal',header:'Población',readOnly:true},
              {name:'provincia_fiscal_id',header:'Provincia',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true},
              {name:'pais_fiscal_id',header:'País',readOnly:true,type:'m2o',primary:'codigo',label:'nombre',showCode:true} ]}})],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }